import React, { useState, useEffect } from 'react';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CustomModal from './CustomModal';
import { axiosInstance } from '../utils/utils';
import Snackbar from './SnackBar';

const OrderComment = ({
  orderId,
  open,
  closeOrderComment,
  accessToken,
}: {
  orderId: string;
  open: boolean;
  closeOrderComment: () => void;
  accessToken: string;
}) => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [isError, setIsError] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [response, setResponse] = useState({ message: '' });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleAddComment = () => {
    addComment();
  };

  useEffect(() => {
    if (!comments.length) fetchComments();
  });

  const fetchComments = async () => {
    const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/getComment`;
    const response = await fetch(url, {
      headers: { Authorization: accessToken },
    })
      .then((resp) => resp.json())
      .catch((error) => console.log(error));
    setComments(response.details);
  };
  const addComment = async () => {
    try {
      const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/addComment`;

      const response = await axiosInstance.post(
        url,
        { comment },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setResponse(response?.data);
      setOpenSnack(true);
      if (response.status === 200) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setResponse(error.response.data);
      setOpenSnack(true);
      setIsError(true);
    }
  };

  const closeSnack = () => {
    setOpenSnack(false);
    setIsError(false);
  };

  return (
    <div>
      <CustomModal title="Update Order" openModal={open} closeModal={closeOrderComment}>
        <TextField
          onChange={handleChange}
          margin="normal"
          fullWidth
          id="order-comment"
          label="Reason"
          variant="outlined"
          multiline
          rows={3}
        />
        <MuiDialogActions>
          <Button onClick={handleAddComment}>Save</Button>
        </MuiDialogActions>
        <Typography variant="h6" gutterBottom>
          Previous Comments:
        </Typography>
        {comments.map((comment: { comments: string }) => (
          <Typography>{comment.comments}</Typography>
        ))}
      </CustomModal>
      {openSnack && <Snackbar message={response?.message} open={openSnack} error={isError} closeSnack={closeSnack} />}
    </div>
  );
};

export default OrderComment;
