import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getAnalytics } from '../api';
import EmptyState from '../components/empty';
import DeliveryStatusChart from '../views/Analytics/DeliveryStatus';
import AnalyticsFilters from '../views/Analytics/Filters';
import AnalyticsHeader from '../views/Analytics/Header';
import ProductSupplyChart from '../views/Analytics/ProductSupply';
import { useAnalyticsStyles } from '../views/Analytics/Styles';
import AnalyticsSummary from '../views/Analytics/Summary';
import AnalyticsTable from '../views/Analytics/Table';

interface AnalyticsProps {
  permissions: string[];
}

function Analytics(props: AnalyticsProps) {
  const classes = useAnalyticsStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [retry, setRetry] = useState(true);

  const { isLoading, data, error = {} } = useQuery<any, any>(
    ['getAnalytics', startDate, endDate],
    () => getAnalytics({ startDate, endDate }),
    {
      refetchInterval: retry ? 300000 : false,
      refetchIntervalInBackground: true,
      onError: () => setRetry(false),
    }
  );

  if (isLoading) return (
    <div className={`analytics ${classes.root}`}>
      <div className="header-wrapper">
        <AnalyticsHeader
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setstartDate={setStartDate}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          data={data?.orderSummary}
        />
      </div>
      <EmptyState loading title="Loading Analytics..." />
    </div>
  );

  if (!data) return (
    <div className={`analytics ${classes.root}`}>
      <div className="header-wrapper">
        <AnalyticsHeader
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setstartDate={setStartDate}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
        />
      </div>
      <EmptyState title={`${error?.response?.data?.message ?? 'No data found!'}`} />
    </div>
  );

  return (
    <div className="analytics">
      <AnalyticsHeader
        endDate={endDate}
        startDate={startDate}
        setEndDate={setEndDate}
        setstartDate={setStartDate}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        data={data.orderSummary}
      />
      <div className={`${classes.filterWrapper} ${classes.sectionMobile}`}>
        <AnalyticsFilters
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setstartDate={setStartDate}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          data={data?.orderSummary}
        />
      </div>
      <div className={classes.summarySection}>
        <AnalyticsSummary caption="Not Started" value={`${data?.orderSummary?.notStarted ?? 0}`} />
        <AnalyticsSummary caption="In-Progress (Shopping)" value={`${data?.orderSummary?.inProgress ?? 0}`} />
        <AnalyticsSummary caption="In-Progress (Awaiting Driver)" value={`${data?.orderSummary?.pendingDriver ?? 0}`} />
        <AnalyticsSummary caption="In-Progress (Delivery)" value={`${data?.orderSummary?.onTheWay ?? 0}`} />
        <AnalyticsSummary caption="Completed" value={`${data?.orderSummary?.completed ?? 0}`} />
        <AnalyticsSummary caption="Completed Early" value={`${data?.orderSummary?.completedEarly ?? 0}`} />
        <AnalyticsSummary caption="Completed On-Time" value={`${data?.orderSummary?.completedOnTime ?? 0}`} />
        <AnalyticsSummary caption="Completed Late" value={`${data?.orderSummary?.completedLate ?? 0}`} />
      </div>
      <div className={classes.summarySection}>
        <AnalyticsSummary caption="Total Items Ordered" value={`${data?.deliverySummary?.itemsOrdered ?? 0}`} />
        <AnalyticsSummary caption="Total Items Supplied" value={`${data?.deliverySummary?.itemsSupplied ?? 0}`} />
        <AnalyticsSummary caption="Fulfilment Rate (%)" value={`${data?.deliverySummary?.fulfillmentRate ?? 0}`} />
        <AnalyticsSummary caption="Perfectly Supplied (Within SLA)" value={`${data?.deliverySummary?.perfectlySupplied ?? 0}`} />
        <AnalyticsSummary caption="Avg. Delivery Time per km (minutes)" value={`${data?.deliverySummary?.avgDeliveryTime ?? 0}`} />
        <AnalyticsSummary caption="ABS (Supplied)" value={`${data?.deliverySummary?.avgBasketSize ?? 0}`} />
        <AnalyticsSummary caption="Queueing & Payment Time (minutes)" value={`${data?.deliverySummary?.avgTillTime ?? 0}`} />
        <div className={`placeholder ${classes.summaryCard}`} />
      </div>
      <div className={classes.tableSection}>
        <AnalyticsTable title='Orders Per Timeslot (Complete vs InComplete)' data={data?.ordersPerTimeslot} />
      </div>
      <div className={classes.tableSection}>
        <DeliveryStatusChart data={data?.orderSummary} />
        <ProductSupplyChart data={data?.deliverySummary} />
      </div>
    </div>
  );

}

export default Analytics;
