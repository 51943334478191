import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Fragment, FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      maxWidth: 550,
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(4),
      minHeight: '15%',
      padding: '10px 25px',
      [theme.breakpoints.down(768)]: {
        fontSize: 13,
        padding: '6px 16px',
        maxWidth: 330,
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
    },

    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },

    skeleton: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    clientDetails: {
      paddingTop: '0',
    },
    title: {
      fontSize: 23,
      color: 'rgba(0, 0, 0, 0.87)',
      [theme.breakpoints.down(768)]: {
        fontSize: 18,
      },
    },
    subTitle: {
      [theme.breakpoints.down(768)]: {
        fontSize: 13,
      },
    },
    text: {
      fontSize: 15,
      [theme.breakpoints.down(768)]: {
        fontSize: 13,
      },
    },
  })
);

interface Order {
  orderID: string;
  externalOrderID: string;
  isCompleted: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
  shopper?: string;
  driver?: string;
}

const OrderDetailSection: FunctionComponent<Order> = (props) => {
  const classes = useStyles();
  const {
    orderID,
    externalOrderID,
    shopName,
    startTime,
    shoppingCompleted,
    deliveryStarted,
    deliveryCompleted,
    shopper,
    driver,
  } = props;

  return (
    <Fragment>
      <Card className={classes.root}>
        <Fragment>
          <div className={classes.details}>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                <b className={classes.title}>Order {orderID}</b>

                {!!externalOrderID && (
                  <div className={classes.text}>
                    <b className={classes.title} style={{ fontSize: 18, fontWeight: 500 }}>
                      External Order ID: {externalOrderID}
                    </b>
                  </div>
                )}
              </Typography>
            </CardContent>
            <CardContent className={classes.clientDetails}>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Store: </b>
                    {shopName || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Shopper: </b>
                    {shopper || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Shopping started: </b>
                    {startTime || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Shopping completed: </b>
                    {shoppingCompleted || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Driver: </b>
                    {driver || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Delivery started: </b>
                    {deliveryStarted || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Delivery completed: </b>
                    {deliveryCompleted || 'xxx'}
                  </div>
                )}
              </Typography>
            </CardContent>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  );
};

export default OrderDetailSection;
