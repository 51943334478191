import { useEffect, useState } from 'react';
import { BreakPoints } from '../theme/breakPoints';

export function useWindowParams() {

  const [isWoolies, setVendorType] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });

  useEffect(() => {

    const handleVendorType = () => {
      console.log(`process.env.REACT_APP_VENDOR`, process.env.REACT_APP_VENDOR);
      const isWooliesEnv = `${process.env.REACT_APP_VENDOR}`.toLowerCase().includes('woolworths');
      const isWooliesURL = window.location.hostname.includes('woolworths');
      setVendorType(isWooliesURL ||isWooliesEnv);
    };

    // Handler to call on window resize
    function handleResize() {
      const { width, height } = window.screen;
      setWindowSize({ width, height });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler methods right away so state gets updated with initial params
    handleVendorType();
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);

  }, []); // Empty array ensures that effect is only run on mount

  return {
    ...windowSize,
    isMobile: windowSize.width <= BreakPoints.sm,
    isWoolies
  };

}
