import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { TableInstance } from 'react-table';
import * as store from 'store';
import { getMallSlots } from '../api';
import EmptyState from '../components/empty';
import Header from '../components/Header';
import SlotsTable from '../components/SlotsTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto 1fr',
    },

    paper: {
      height: '100%',
      objectFit: 'none',
      borderRadius: theme.spacing(1),
      color: theme.palette.text.secondary,
      border: '1px solid #E0E0E0',
      padding: theme.spacing(1),
    },

    container: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
    },

    wrapper: {
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        padding: '16px',
        paddingTop: '0px',
      },
    },

    filterButtonsContainer: {
      minHeight: '75px',
      margin: '0 auto',
    },

    diviButtonsContainer: {
      width: '100%',
      padding: '24px',
    },

    diviButtons: {
      display: 'flex',
      position: 'relative',
    },

    buttons: {
      padding: '12px 20px',
      border: '1px solid #D3D3D3',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: '14px',
      fontWeight: 600,
      cursor: 'pointer',
      background: 'white',
      boxShadow: '0 2px 10px 0px rgba(0,0,0,0.04)',
      '&.active, &:focus': {
        color: '#57A946',
      },
    },

    leftButton: {
      borderRadius: '5px 0px 0px 5px',
    },

    rightButton: {
      borderRadius: '0px 5px 5px 0px',
    },
  })
);

const initialSlotsState = {
  slots: [],
};

const slotsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'INITIAL':
      return { ...state, slots: action.data };
    case 'ONECART':
      return { ...state, slots: action.data };
    case 'WOOLWORTHS':
      return { ...state, slots: action.data };
    default:
      return state;
  }
};

const MallSlots: React.FC = () => {
  const tableInstance = useRef<TableInstance>(null);
  const classes = useStyles();
  const [slots, dispatchSlots] = useReducer(slotsReducer, initialSlotsState);

  const accessToken = store.get('spotlightAccessToken');

  const { isLoading: loadingSlots, data: mallSlots } = useQuery(
    ['mallSlots', accessToken],
    () => getMallSlots({ accessToken }),
    { refetchInterval: 180000 }
  );

  useEffect(() => {
    if (mallSlots && slots.slots.length < 1) dispatchSlots({ type: 'INITIAL', data: mallSlots?.onecartSlots });
  }, [mallSlots, slots]);

  return (
    <Container className={classes.root} maxWidth={false}>
      <Header tableInstance={tableInstance} activeTab={'mall-slots'} />

      <DiviButtons dispatchSlots={dispatchSlots} mallSlots={mallSlots} />

      <Grid container className={classes.container}>
        <Box p={3} className={classes.wrapper}>
          <Box>
            <Paper className={classes.paper} component={'div'}>
              {!!loadingSlots && <EmptyState loading title="Loading Slots..." />}
              {!loadingSlots && <SlotsTable data={slots.slots ?? []} ref={tableInstance} />}
            </Paper>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

const DiviButtons = (props: any) => {
  const classes = useStyles();
  const { dispatchSlots, mallSlots } = props;
  const [activeTab, setActiveTab] = useState('ONECART');

  const handleTabClick = (type: string) => {
    let data = [];
    setActiveTab(type);

    if (type === 'ONECART') data = mallSlots?.onecartSlots;
    if (type === 'WOOLWORTHS') data = mallSlots?.woolworthsSlots;

    return dispatchSlots({ type, data });
  };

  return (
    <div className={classes.filterButtonsContainer}>
      <Grid container className={classes.diviButtonsContainer}>
        <div className={classes.diviButtons}>
          <button
            className={`${classes.buttons} ${classes.leftButton} ${activeTab === 'ONECART' ? 'active' : ''}`}
            onClick={() => handleTabClick('ONECART')}
          >
            OneCart
          </button>
          <button
            className={`${classes.buttons} ${classes.rightButton} ${activeTab === 'WOOLWORTHS' ? 'active' : ''}`}
            onClick={() => handleTabClick('WOOLWORTHS')}
          >
            WoolWorths
          </button>
        </div>
      </Grid>
    </div>
  );
};

export default MallSlots;
