import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { onBackgroundMessage, isSupported } from "firebase/messaging/sw";
import * as store from 'store';

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const messaging = getMessaging(firebaseApp);

getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
  if (currentToken) {
    // store firebaseToken to use on online
    store.set('firebaseToken', currentToken);

    onMessage(messaging, (payload) => {
        // no need for this, the data & toast is completely ignored and only show data from firebase pushed by the backend;
        // use the data from here to later create a notification panel in on the web itself for tracking notifications
        // const notificationTitle = payload.notification?.title ?? payload.data?.title ?? '';
    });
    
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    requestNotificationPermission();
    // ...
  }
}).catch((err) => {
  console.error('An error occurred while retrieving token. ', err);
  // ...
});

const requestNotificationPermission = () => {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  });
}