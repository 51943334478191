import React, { FunctionComponent, Fragment } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { getCustomerDetails } from '../../api';
import { useHistory } from 'react-router-dom';
import * as store from 'store';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import StatusIcon from '../StatusIcon';
import onhold from '../../images/onhold.png';
import call from '../../images/call.png';
import newuser from '../../images/newuser.png';
import returncustomer from '../../images/returncustomer.png';
import vipcustomer from '../../images/vipcustomer.png';
import scooterGreen from '../../images/scooter-green.png';
import scooterYellow from '../../images/scooter-yellow.png';
import scooterPurple from '../../images/scooter-purple.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(4),
      minHeight: '15%',
      padding: '10px 25px',
      width: '100%',
      [theme.breakpoints.down(768)]: {
        flexDirection: 'column',
        height: 'fit-object',
        padding: '0px',
        margin: theme.spacing(0),
        width: '100%',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },

    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },

    skeleton: {
      width: 300,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    clientDetails: {
      paddingTop: '0',
    },
    subTitle: {
      [theme.breakpoints.down(768)]: {
        fontSize: 13,
      },
    },
    title: {
      fontSize: '23px',
      color: 'rgba(0, 0, 0, 0.87)',
      [theme.breakpoints.down(768)]: {
        fontSize: 14,
      },
    },
    text: {
      [theme.breakpoints.down(768)]: {
        fontSize: 13,
      },
    },
    back: {
      width: '80%',
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 'auto',
      [theme.breakpoints.down(768)]: {
        fontSize: 12,
      },
    },
  })
);

interface Order {
  orderID: string;
  externalOrderID: string;
  isCompleted: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
  customerType: string;
  shopper: string;
  driver: string;
}

const MobileOrderDetailSection: FunctionComponent<Order> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    orderID,
    externalOrderID,
    shopName,
    startTime,
    shoppingCompleted,
    deliveryStarted,
    deliveryCompleted,
    customerType,
    shopper,
    driver,
  } = props;
  const accessToken = store.get('spotlightAccessToken');

  const { isLoading, data } = useQuery(['customerDetails', accessToken, orderID], () =>
    getCustomerDetails({ accessToken, orderID })
  );

  const backToOrders = () => {
    history.push('/orders');
  };

  return (
    <Fragment>
      <div className={classes.back}>
        <div className={classes.button}>
          <Button
            color="default"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              // setChannel(null);
              // setAssigned(false);
              // setCurrentOrderID(null);
              backToOrders();
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <Card className={classes.root}>
        <Fragment>
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              {isLoading ? (
                <Skeleton animation="wave" height={20} width="70%" />
              ) : (
                <b className={classes.title}>
                  {data?.data.details[0].customerName} {data?.data.details[0].customerSurname}
                </b>
              )}
            </Typography>
            <div>
              {isLoading ? (
                <Skeleton animation="wave" height={20} width="70%" />
              ) : (
                <div style={{ display: 'flex' }}>
                  <Typography style={{ paddingTop: '6px' }} variant="subtitle1">
                    <b className={classes.subTitle} style={{ paddingRight: '10px' }}>
                      Status:
                    </b>
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <StatusIcon display={Number(customerType) === 1} imageUrl={newuser} />
                    <StatusIcon display={Number(customerType) === 2} imageUrl={returncustomer} />
                    <StatusIcon display={Number(customerType) === 3} imageUrl={vipcustomer} />
                    <StatusIcon display={data?.data.details[0].driverStatus === 1} imageUrl={scooterGreen} />
                    <StatusIcon display={data?.data.details[0].driverStatus === 2} imageUrl={scooterYellow} />
                    <StatusIcon display={data?.data.details[0].driverStatus === 3} imageUrl={scooterPurple} />
                    <StatusIcon display={data?.data.details[0].onHold === 1} imageUrl={onhold} />
                    <StatusIcon display={data?.data.details[0].hasCalled === 1} imageUrl={call} />
                  </div>
                </div>
              )}
            </div>
          </CardContent>

          <div className={classes.details}>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                <b className={classes.title}>Order {orderID}</b>
                <b className={classes.title}>External Order ID {externalOrderID}</b>
              </Typography>
            </CardContent>
            <CardContent className={classes.clientDetails}>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Store: </b>
                    {shopName || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Shopper: </b>
                    {shopper || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Shopping started: </b>
                    {startTime || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Shopping completed: </b>
                    {shoppingCompleted || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Driver: </b>
                    {driver || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Delivery started: </b>
                    {deliveryStarted || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div className={classes.text}>
                    <b className={classes.subTitle}>Delivery completed: </b>
                    {deliveryCompleted || 'xxx'}
                  </div>
                )}
              </Typography>
            </CardContent>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  );
};

export default MobileOrderDetailSection;
