import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomModal from './CustomModal';
import { axiosInstance } from '../utils/utils';
import Snackbar from './SnackBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
interface OrderTransactionsProps {
  orderId: string;
  accessToken: string;
  open: boolean;
  closeTransactions: React.ReactEventHandler<{}>;
  viewMakeRefund: React.ReactEventHandler<{}>;
}

interface Refund {
  reason: string;
  date: string;
  refund: number;
}

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    bottom: '25px',
  },
  title: {
    textAlign: 'center',
  },
}));

export default function OrderTransactions(props: OrderTransactionsProps) {
  const { open, orderId, accessToken, closeTransactions, viewMakeRefund } = props;
  const classes = useStyles();

  const [transactions, setTransactions] = useState({
    deliveryFee: 0,
    discount: 0,
    foodForward: 0,
    netTotal: 0,
    orderID: 0,
    serviceFee: 0,
    subTotal: 0,
    tips: 0,
    refund: [] as Refund[],
  });
  const [isError, setIsError] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [response, setResponse] = useState({ message: '' });

  const getTransactions = async (orderId: string, accessToken: string) => {
    try {
      const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/transactions`;

      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: accessToken,
        },
      });
      setResponse(response?.data);
      setOpenSnack(true);
      if (response.status === 200) {
        setTransactions(response.data.details);
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setResponse(error.response.data);
      setOpenSnack(true);
      setIsError(true);
    }
  };

  useEffect(() => {
    getTransactions(orderId, accessToken);
  }, [orderId, accessToken]);

  const closeSnack = () => {
    setOpenSnack(false);
    setIsError(false);
  };
  return (
    <div>
      <CustomModal
        closeModal={closeTransactions}
        height="40%"
        openModal={open}
        title={`Transactions for order ${orderId}`}
      >
        <Typography className={classes.title} variant="h5">
          Transactions
        </Typography>
        <List aria-label="transactions">
          <ListItem>
            <ListItemText disableTypography primary="Sub total: " secondary={`R${transactions.subTotal.toFixed(2)}`} />
          </ListItem>
          <ListItem>
            <ListItemText disableTypography primary="Delivery fee: " secondary={`R${transactions.deliveryFee}`} />
          </ListItem>
          <ListItem>
            <ListItemText disableTypography primary="Service fee: " secondary={`R${transactions.serviceFee}`} />
          </ListItem>

          {transactions.discount > 0 && (
            <ListItem>
              <ListItemText disableTypography primary="Discount: " secondary={`R${transactions.discount}`} />
            </ListItem>
          )}
          {transactions.foodForward > 0 && (
            <ListItem>
              <ListItemText disableTypography primary="Food forward: " secondary={`R${transactions.foodForward}`} />
            </ListItem>
          )}
          {transactions.tips > 0 && (
            <ListItem>
              <ListItemText disableTypography primary="Tips: " secondary={`R${transactions.tips}`} />
            </ListItem>
          )}
          <ListItem>
            <ListItemText disableTypography primary="Total: " secondary={`R${transactions.netTotal.toFixed(2)}`} />
          </ListItem>
        </List>
        <Typography className={classes.title} variant="h5">
          Refunds
        </Typography>

        {transactions.refund.length > 0 ? (
          <Table aria-label="transactions table">
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Reason </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.refund.map((refund) => (
                <TableRow key={refund.reason}>
                  <TableCell>R{refund.refund}</TableCell>
                  <TableCell>{refund.date ? new Date(refund.date).toDateString() : 'Date not provided'}</TableCell>
                  <TableCell>{refund.reason}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="h6">No refund for this order</Typography>
        )}

        <Button className={classes.button} variant="contained" onClick={viewMakeRefund} color="primary">
          Refund
        </Button>
      </CustomModal>
      {openSnack && <Snackbar message={response?.message} open={openSnack} error={isError} closeSnack={closeSnack} />}
    </div>
  );
}
