import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';
import React, { ChangeEvent, Fragment, FunctionComponent, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as store from 'store';
import { getShoppingList, getTransactions, makeRefund } from '../api';
import EmptyState from '../components/empty';
import { BreakPoints } from '../theme/breakPoints';
import CustomModal from './CustomModal';
import { createToastMessage } from './toast';

interface TransactionsProps {
  orderID: string;
}
interface MakeRefundProps {
  closeRefund: () => void;
  transactions: TransactionsType;
  openRefund: boolean;
  refetch: Function;
}

interface PartialRefundProps {
  transactions: TransactionsType;
  accessToken: string;
  setRefundAmount: React.Dispatch<React.SetStateAction<number>>;
}

interface FullRefundProps {
  transactions: TransactionsType;
  setRefundAmount: React.Dispatch<React.SetStateAction<number>>;
}

interface Refund {
  date: string;
  refund: number;
}

interface TransactionsType {
  foodForward: number;
  deliveryFee: number;
  serviceFee: number;
  discount: number;
  refund: Refund[];
  netTotal: number;
  subTotal: number;
  orderID: string;
  tips: number;
}

interface Vendor {
  vendor: string;
  products: Product[];
}

interface Product {
  name: string;
  price: number;
  status: number;
  imageURL: string;
  productID: number;
  suppliedQuantity: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.down(BreakPoints.sm)]: {
        width: '100%',
        flexDirection: 'column'
      }
    },
    list: {
      width: '50%',
      marginRight: theme.spacing(3),
      [theme.breakpoints.down(BreakPoints.sm)]: {
        width: '100%',
        marginBottom: theme.spacing(3),
        marginRight: 0
      }
    },
    listItem: {
      alignItems: 'unset',
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
    itemText: {
      maxWidth: '50%',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      float: 'right',
    },
    buttonContainer: {
      display: 'block',
      height: theme.spacing(7),
    },
    centerAlignDialogActions: {
      justifyContent: 'center',
    },
    refundItems: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    accordionDetails: {
      display: 'flex',
      padding: '8px 16px 16px',
      flexDirection: 'column',
    },
    error: {
      '& > *': {
        color: theme.palette.error.main,
        fontWeight: 500,
      },
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      id={`simple-tabpanel-${index}`}
      hidden={value !== index}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MakeRefund: FunctionComponent<MakeRefundProps> = (props) => {
  const { closeRefund, openRefund, transactions, refetch } = props;
  const accessToken = store.get('spotlightAccessToken');
  const { orderID } = transactions;

  const [isValidReason, setIsValidReason] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [reason, setReason] = useState('');
  const classes = useStyles();

  const makeRefundMutation = useMutation(makeRefund, {
    onSuccess: () => {
      refetch();
      closeRefund();
    },
  });

  const handleSetRefundReason = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);

    const reasonWords = event.target.value.split(' ');

    setIsValidReason(reasonWords.length >= 3 && reasonWords.splice(0, 3).every(({ length }) => length > 0));
  };

  const handleMakeRefund = () => {
    if (accessToken) {
      makeRefundMutation.mutate(
        { orderID, reason, amount: `${refundAmount}`, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setRefundAmount(0);
    setValue(newValue);
  };

  return (
    <CustomModal openModal={openRefund} closeModal={closeRefund} title={`Refund order ${orderID}`} height="auto">
      <Typography color="primary" align="center" variant="h6" style={{ marginBottom: '2em' }}>
        Total Refund Amount: R{(refundAmount || 0).toFixed(2)}
      </Typography>

      <Tabs
        aria-label="Order Refund Modes"
        indicatorColor="primary"
        onChange={handleChange}
        variant="fullWidth"
        textColor="primary"
        value={value}
        centered
      >
        <Tab label="Partial Refund" id="partial-refund-tab" aria-controls="partial-refund-tab" />
        <Tab label="Full Refund" id="full-refund-tab" aria-controls="full-refund-tab" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Box>
          <PartialRefund transactions={transactions} accessToken={accessToken} setRefundAmount={setRefundAmount} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <FullRefund transactions={transactions} setRefundAmount={setRefundAmount} />
        </Box>
      </TabPanel>

      <TextField
        onChange={handleSetRefundReason}
        label="Reason for refund"
        variant="outlined"
        id="refund-reason"
        margin="normal"
        fullWidth
        multiline
        rows={2}
      />

      <MuiDialogActions classes={{ root: classes.centerAlignDialogActions }}>
        <Button variant="contained" color="primary" onClick={handleMakeRefund} disabled={!isValidReason}>
          Refund
        </Button>
      </MuiDialogActions>
    </CustomModal>
  );
};

const RefundItemCounterButtons = ({ product, setRefundAmount }: { product: Product; setRefundAmount: any }) => {
  const [counter, setCounter] = useState(0);

  const handleCounter = (isAddition: boolean) => {
    setCounter(isAddition ? counter + 1 : counter - 1);
    setRefundAmount((currentState: number) =>
      isAddition ? currentState + product.price : currentState - product.price
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-12px' }}>
      <IconButton color="primary" disabled={counter < 1} onClick={() => handleCounter(false)}>
        <RemoveCircleOutlineIcon />
      </IconButton>
      <b style={{ width: '20px', textAlign: 'center' }}>{counter}</b>
      <IconButton color="primary" disabled={counter === product.suppliedQuantity} onClick={() => handleCounter(true)}>
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  );
};

const PartialRefund: FunctionComponent<PartialRefundProps> = (props) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const classes = useStyles();

  const { transactions, accessToken, setRefundAmount } = props;
  const { orderID } = transactions;
  let vendors = [];

  const { data, isLoading } = useQuery(['shoppingList', accessToken, orderID], () =>
    getShoppingList({ accessToken, orderID })
  );
  if (data?.data) vendors = data?.data.details;

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (isLoading) return <EmptyState loading title="Loading Order Products..." />;

  return (
    <Fragment>
      {vendors.length > 0 ? (
        <div style={{ width: '100%', padding: '1.5em 0' }}>
          {vendors.map(({ vendor, products: allProducts }: Vendor) => {
            const products = allProducts.filter(({ status }) => status === 1);

            const maxRefundableAmount = products
              .map(({ suppliedQuantity, price }) => suppliedQuantity * price)
              .reduce((partialSum: number, a: number) => partialSum + a, 0);

            return (
              <Accordion expanded={expanded === vendor} onChange={handleAccordionChange(vendor)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <FormControlLabel
                    control={
                      <Avatar
                        style={{ marginRight: '16px', border: '1.5px solid #57A946', width: 65, height: 65 }}
                        src={`https://onecart.s3-eu-west-1.amazonaws.com/website/logos/${vendor}.png`}
                        alt={`Avatar`}
                      />
                    }
                    label={
                      <ListItemText
                        primary={`${vendor} (${products.length} Item${products.length > 1 ? 's' : ''})`}
                        secondary={`Total: R${maxRefundableAmount.toFixed(2)}`}
                      />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <List dense className={classes.refundItems}>
                    <Typography color="textPrimary">Products</Typography>

                    {products.map((product) => (
                      <ListItem key={product.productID} className={classes.listItem}>
                        <ListItemAvatar>
                          <Avatar
                            style={{ border: '1.5px solid #57A946', margin: '4px 8px 0 0', width: 68, height: 68 }}
                            alt={`Product: ${product.productID}`}
                            src={product.imageURL}
                            variant="rounded"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          style={{ margin: 0 }}
                          id={`${product.productID}`}
                          primary={product.name}
                          secondary={
                            <>
                              {`${product.suppliedQuantity} Item${
                                product.suppliedQuantity > 1 ? 's' : ''
                              } x R${product.price.toFixed(2)}`}

                              <RefundItemCounterButtons product={product} setRefundAmount={setRefundAmount} />
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : (
        <Typography color="error" align="center">
          Products not found for Order: {orderID}
        </Typography>
      )}
    </Fragment>
  );
};

const FullRefund: FunctionComponent<FullRefundProps> = (props) => {
  const { transactions, setRefundAmount } = props;
  const { deliveryFee, netTotal, serviceFee, subTotal, tips, refund } = transactions;

  const orderRefundAmount = refund
    .map(({ refund }) => refund)
    .reduce((partialSum: number, a: number) => partialSum + a, 0);

  const classes = useStyles();
  setRefundAmount(netTotal - orderRefundAmount);

  return (
    <Box p={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      <List
        className={classes.list}
        subheader={
          <Fragment>
            <ListSubheader component="div" id="nested-list-subheader">
              Full Refund Amount Breakdown
            </ListSubheader>
            <Divider />
          </Fragment>
        }
      >
        {orderRefundAmount > 0 && (
          <ListItem>
            <ListItemText className={clsx(classes.itemText, classes.error)} secondary="Refunded: " />
            <ListItemText
              className={clsx(classes.itemText, classes.error)}
              primary={`- R${orderRefundAmount.toFixed(2)}`}
            />
          </ListItem>
        )}

        {subTotal > 0 && (
          <ListItem>
            <ListItemText className={classes.itemText} secondary="Sub Total: " />
            <ListItemText className={classes.itemText} primary={`R${(subTotal - orderRefundAmount).toFixed(2)}`} />
          </ListItem>
        )}

        {deliveryFee > 0 && (
          <ListItem>
            <ListItemText className={classes.itemText} secondary="Delivery Fee: " />
            <ListItemText className={classes.itemText} primary={`R${deliveryFee.toFixed(2)}`} />
          </ListItem>
        )}
        {serviceFee > 0 && (
          <ListItem>
            <ListItemText className={classes.itemText} secondary="Service Fee: " />
            <ListItemText className={classes.itemText} primary={`R${serviceFee.toFixed(2)}`} />
          </ListItem>
        )}
        {tips > 0 && (
          <ListItem>
            <ListItemText className={classes.itemText} secondary="Tip: " />
            <ListItemText className={classes.itemText} primary={`R${tips.toFixed(2)}`} />
          </ListItem>
        )}
        <Divider />
        {netTotal > 0 && (
          <ListItem>
            <ListItemText className={classes.itemText} primary="Total: " />
            <ListItemText className={classes.itemText} primary={`R${(netTotal - orderRefundAmount).toFixed(2)}`} />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

const Transactions: FunctionComponent<TransactionsProps> = (props) => {
  const { orderID } = props;
  const accessToken = store.get('spotlightAccessToken');
  const classes = useStyles();
  let transactions: TransactionsType = {
    deliveryFee: 0,
    discount: 0,
    foodForward: 0,
    netTotal: 0,
    orderID: '',
    refund: [],
    serviceFee: 0,
    subTotal: 0,
    tips: 0,
  };

  const { data, refetch, isLoading } = useQuery(['transactions', accessToken, orderID], () =>
    getTransactions({ accessToken, orderID })
  );
  const [openRefund, setOpenRefund] = useState(false);

  if (data?.data) transactions = data?.data.details;

  if (isLoading) return (
    <EmptyState loading/>
  )

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          size="large"
          variant="outlined"
          className={classes.button}
          startIcon={<AccountBalanceIcon color="primary" />}
          onClick={() => setOpenRefund(true)}
        >
          Make refund
        </Button>
      </div>
      <div className={classes.root}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Order Cost
            </ListSubheader>
          }
        >
          {transactions.subTotal > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Sub Total: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.subTotal}`} />
            </ListItem>
          )}
          {transactions.deliveryFee > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Delivery Fee: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.deliveryFee}`} />
            </ListItem>
          )}
          {transactions.serviceFee > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Service Fee: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.serviceFee}`} />
            </ListItem>
          )}
          {transactions.tips > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Tip: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.tips}`} />
            </ListItem>
          )}
          <Divider />
          {transactions.netTotal > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} primary="Total: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.netTotal}`} />
            </ListItem>
          )}
        </List>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Refunds
            </ListSubheader>
          }
        >
          {transactions.refund.length > 0
            ? transactions.refund.map((refund) => (
                <ListItem>
                  <ListItemText
                    className={classes.itemText}
                    secondary={refund?.date ? new Date(refund?.date).toDateString() : 'Date not provided'}
                  />
                  <ListItemText className={classes.itemText} primary={`R ${refund.refund}`} />
                </ListItem>
              ))
            : 'No refunds for this order'}
        </List>
      </div>
      {openRefund && (
        <MakeRefund
          closeRefund={() => setOpenRefund(false)}
          transactions={transactions}
          openRefund={openRefund}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default Transactions;
