import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as store from 'store';
import CustomerDetails from '../components/CustomerDetails';
import Header from '../components/Header';
import MobileOrderDetailSection from '../components/mobile/MobileOrderDetailSection';
import OrderActionButtons from '../components/OrderActionButtons';
import OrderDetailSection from '../components/OrderDetailSection';
import OrderList from '../components/OrderList';

interface ParamTypes {
  orderID: string;
}

enum SpotlightPermission {
  DELETE_ORDER = 'delete_order',
  EDIT_ORDER = 'edit_order',
  ASSIGN_AGENT = 'assign_agent',
  CREATE_PICUP = 'create_picup',
  SPOTLIGHT = 'spotlight',
  VENDOR_SWITCH = 'vendor_switch',
  VENDOR_TOGGLE = 'vendor_toggle',
  ORDER_WEBHOOK = 'order_webhook',
  HOLD_ORDER = 'hold_order',
  UPDATE_PRICE = 'update_price',
  SEE_ALL = 'see_all',
  FILTER_DATE = 'filter_date',
  FILTER_SHOPS = 'filter_shops',
  AUTO_ASSIGN = 'auto_assign',
  ALL_SHOPS = 'all_shops',
  RESET_ORDER = 'reset_order',
}
interface IState {
  externalOrderID: string;
  shopper: string;
  isCompleted: string;
  customerType: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
  driver: string;
}

const Orders: React.FC = () => {
  const location = useLocation();
  let { orderID } = useParams<ParamTypes>();
  const externalOrderID = (location.state as IState)?.externalOrderID;
  const shopper = (location.state as IState)?.shopper;
  const isCompleted = (location.state as IState)?.isCompleted;
  const customerType = (location.state as IState)?.customerType;
  const orderTypeID = (location.state as IState)?.orderTypeID;
  const shopName = (location.state as IState)?.shopName;
  const startTime = (location.state as IState)?.startTime;
  const shoppingCompleted = (location.state as IState)?.shoppingCompleted;
  const deliveryStarted = (location.state as IState)?.deliveryStarted;
  const deliveryCompleted = (location.state as IState)?.deliveryCompleted;
  const agentID = (location.state as IState)?.agentID;
  const driver = (location.state as IState)?.driver;

  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const getPermissions = async () => {
      const userPermissions: string[] = store.get('permissions');

      if (userPermissions) setPermissions(userPermissions);
    };
    getPermissions();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Container style={{ paddingTop: '20px', height: '100%' }} maxWidth={false}>
        <Grid container spacing={3}>
          {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
            permissions?.includes(SpotlightPermission.ASSIGN_AGENT) && (
              <Grid item xs={12} sm={12}>
                <OrderActionButtons shopper={shopper} orderID={orderID} />
              </Grid>
            )}
          <Grid container direction="column" style={{ height: '100%' }} item xs={12} sm={8} md={4}>
            {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
              permissions?.includes(SpotlightPermission.ASSIGN_AGENT) && (
                <CustomerDetails customerType={customerType} orderID={orderID} />
              )}

            {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
            permissions?.includes(SpotlightPermission.ASSIGN_AGENT) ? (
              <OrderDetailSection
                orderTypeID={orderTypeID}
                isCompleted={isCompleted}
                orderID={orderID}
                externalOrderID={externalOrderID}
                shopName={shopName}
                startTime={startTime}
                shoppingCompleted={shoppingCompleted}
                deliveryStarted={deliveryStarted}
                deliveryCompleted={deliveryCompleted}
                agentID={agentID}
                shopper={shopper}
                driver={driver}
              />
            ) : (
              <MobileOrderDetailSection
                orderTypeID={orderTypeID}
                isCompleted={isCompleted}
                orderID={orderID}
                externalOrderID={externalOrderID}
                shopName={shopName}
                startTime={startTime}
                shoppingCompleted={shoppingCompleted}
                deliveryStarted={deliveryStarted}
                deliveryCompleted={deliveryCompleted}
                agentID={agentID}
                customerType={customerType}
                shopper={shopper}
                driver={driver}
              />
            )}
          </Grid>
          {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
            permissions?.includes(SpotlightPermission.ASSIGN_AGENT) && (
              <Grid item xs={12} sm={12} md={8}>
                <OrderList orderID={orderID} orderTypeID={orderTypeID} isCompleted={isCompleted} />
              </Grid>
            )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Orders;
