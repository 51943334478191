import { useAuth0 } from '@auth0/auth0-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as store from 'store';
import Loading from '../components/Loader';
import { usePermissions } from '../hooks/usePermissions';
import { SpotlightPermission } from '../types/enums';
import { axiosInstance } from '../utils/utils';
import LoginButton from '../views/Login/LoginButton';
import LoginImage from '../views/Login/LoginImage';

const useStyles = makeStyles((theme) => ({
  login: {
    padding: '20px',
    height: '100%',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    padding: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    border: '1px solid #F0F0F0',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '18px',
    flex: '1 0 auto',
    paddingLeft: '24px',
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0px !Important',
      marginTop: '24px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '12px',
    },
  },
}));

const spotlightOnline = (accessToken?: string) => {
  return axiosInstance.post(
    '/v1/spotlight/online',
    {
      deviceToken: store.get('firebaseToken') ?? undefined
    },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
};

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { updatePermissions, isPermitted } = usePermissions();
  const { isAuthenticated, getAccessTokenSilently, isLoading: isLoadingAuth } = useAuth0();

  const pushToOrders = (permissions: string[]) => {
    const canViewOrders = isPermitted([SpotlightPermission.ALL_SHOPS], 'all', permissions);
    const canViewAnalytics = isPermitted([SpotlightPermission.VIEW_ANALYTICS], 'all', permissions);

    if (!canViewOrders && !!canViewAnalytics) return history.push('/analytics');
    if (store.get('spotlightAccessToken') !== null) {

      const deeplink = store.get('deeplink');
      const excludePaths = ['orders', 'analytics', ''];

      if (deeplink && !excludePaths.includes(deeplink)) {
        history.push(`/${deeplink}`);
        store.remove('deeplink');
      } else {
        history.push('/orders');
      }
    }
  };

  const onSpotlightLogin = (response: any, accessToken?: string) => {
    const expiresAt = JSON.stringify(86400 * 1000 + new Date().getTime());
    const permissions = response?.data?.details?.permissions ?? [];
    store.set('spotlightAccessToken', accessToken);
    store.set('permissions', permissions);
    store.set('expiresAt', expiresAt);
    updatePermissions(permissions);
    pushToOrders(permissions);
  };

  let { isLoading: isLoadingToken, data: accessToken } = useQuery(['accessToken'], getAccessTokenSilently, {
    // The query will not execute until isAuthenticated is true
    enabled: !!isAuthenticated,
  });

  const { isLoading } = useQuery(['spotlight', accessToken], () => spotlightOnline(accessToken), {
    // The query will not execute until the accessToken exists
    onSuccess: (res) => onSpotlightLogin(res, accessToken),
    enabled: !!(accessToken && isAuthenticated),
    retry: false,
  });

  if (isLoading || isLoadingToken || isLoadingAuth) return <Loading />;

  return (
    <div className={classes.login}>
      <Card className={classes.container}>
        <LoginImage />

        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
              Sign in to your account
            </Typography>
            <LoginButton />
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

export default Login;
