import React, { FunctionComponent, Fragment } from 'react';
import CustomModal from './CustomModal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useQuery, useMutation } from 'react-query';
import { resetOrder, getResetPermission } from '../api';
import * as store from 'store';
import { createToastMessage } from './toast';
import Divider from '@material-ui/core/Divider';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import EmptyState from './empty';

interface RescheduleOrderProps {
  open: boolean;
  closeResetOrder: () => void;
  orderID: string;
  refetchActivityHistory: () => void;
  shopper: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerAlignDialogActions: {
      justifyContent: 'center',
    },
  })
);

const resetReasons = [
  {
    resetReason: 'Incorrect location',
  },
  {
    resetReason: 'Timeslot change',
  },
  {
    resetReason: 'No card available',
  },
  {
    resetReason: 'Card decline',
  },
  {
    resetReason: 'Failed Delivery',
  },
  {
    resetReason: 'Missing or lost items in order',
  },
  {
    resetReason: 'Product quality or customer complaint',
  },
];

const ResetOrder: FunctionComponent<RescheduleOrderProps> = (props) => {
  const { open, closeResetOrder, orderID, refetchActivityHistory, shopper } = props;
  const classes = useStyles();
  const accessToken = store.get('spotlightAccessToken');
  const [reason, setReason] = React.useState({ resetReason: '' });
  const [hasRefundProof, setHasRefundProof] = React.useState('');
  const [driverHasOrder, setDriverHasOrder] = React.useState('');
  const [orderPictureProof, setOrderPictureProof] = React.useState('');

  let resetPermission;

  const resetOrderMutation = useMutation(resetOrder);

  const { data, isLoading } = useQuery<Awaited<ReturnType<typeof getResetPermission>> | undefined, Error>(
    ['resetPermission', accessToken, orderID],
    async () => await getResetPermission({ accessToken, orderID }),
    {
      enabled: !!accessToken,
    }
  );

  if (data?.data) {
    resetPermission = data?.data.details;
  }
  const handleResetOrder = () => {
    if (accessToken) {
      const resetShopper = shopper ? 1 : 0;
      resetOrderMutation.mutate(
        {
          orderID,
          accessToken,
          resetShopper,
          reason: reason.resetReason,
          hasRefundProof,
          driverHasOrder,
          orderPictureProof,
        },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleResetReason = (resetReason: string) => {
    setReason({ resetReason: resetReason });
  };

  const handleRefundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasRefundProof(event.target.value);
  };
  const handleDeliveredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDriverHasOrder(event.target.value);
  };
  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderPictureProof(event.target.value);
  };

  return (
    <Fragment>
      <CustomModal title="Reset order" openModal={open} closeModal={closeResetOrder}>
        <div>
          {isLoading && <EmptyState loading title="Loading Questions..." />}

          <div>
            {resetPermission && resetPermission.needsRefundProof && (
              <div>
                <FormControl>
                  <FormLabel id="transactions">
                    Do you have proof of refund for transactions made on this order?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="transactions-row"
                    name="row-radio-buttons-group"
                    value={hasRefundProof}
                    onChange={handleRefundChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {resetPermission && resetPermission.needsDriverProof && (
              <div>
                <FormControl>
                  <FormLabel id="delivery-started">Does the driver still have the order?</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="delivery-started-row"
                    name="row-radio-buttons-group"
                    value={driverHasOrder}
                    onChange={handleDeliveredChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {resetPermission && resetPermission.needsDriverProof && (
              <div>
                <FormControl>
                  <FormLabel id="delivery-started">Do you have a picture from the driver of the order?</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="transactions-row"
                    name="row-radio-buttons-group"
                    value={orderPictureProof}
                    onChange={handlePictureChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
          <br />
          <Typography>Please select a reason for order reset</Typography>
          <List component="nav">
            {resetReasons.map(({ resetReason }) => (
              <Fragment key={resetReason}>
                <ListItem
                  selected={reason.resetReason === resetReason}
                  button
                  onClick={() => handleResetReason(resetReason)}
                >
                  <ListItemText primary={resetReason} />
                </ListItem>

                <Divider />
              </Fragment>
            ))}
          </List>
          <MuiDialogActions classes={{ root: classes.centerAlignDialogActions }}>
            <Button variant="contained" color="primary" onClick={handleResetOrder}>
              Reset Order
              {resetOrderMutation.isLoading && (
                <CircularProgress color="secondary" size={15} style={{ marginLeft: 3 }} />
              )}
            </Button>
          </MuiDialogActions>
        </div>
      </CustomModal>
    </Fragment>
  );
};

export default ResetOrder;
