import axios from 'axios';
import moment from 'moment';
import { Order } from '../types';
import { OrderStatus } from '../types/enums';

declare interface Product {
  productID: number;
  name: string;
  price: number;
  status: number;
  imageURL: string;
  orderQuantity: number;
  suppliedQuantity: number;
  substitutionName: string;
  substitutionID: number;
  substitutionQuantity: number;
  substitutionPrice: number;
  substitutionImageURL?: string;
  vendor: string;
  vendorID?: number;
  note?: string;
  total?: number;
}

enum ShopperOrderProductStatus {
  NOT_DONE = 0,
  COMPLETED = 1,
  CUSTOMER_SUB = 2,
  REFUND = 3,
  SHOPPER_SUB = 4,
  PARTIAL = 5,
  WAITING = 6,
}

export const getUrlWithQueryParams = (url: string, params: any) => {
  let completeURL = new URL(url);
  Object.keys(params).forEach((key) => completeURL.searchParams.append(key, params[key]));
  return completeURL;
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SLS_API_ENDPOINT,
  headers: {
    'Content-type': 'application/json',
  },
});

export const groupOrders = (orders: any) => {
  let totalOrders = 0;
  let totalCompleted = 0;
  let notStarted = 0;
  let runningLate = 0;
  let inProgress = 0;
  let completedLate = 0;

  if (orders && orders.length > 0) {
    totalOrders = orders.length;
    for (const order of orders) {
      if (order.original.status === 2) {
        notStarted++;
      }
      if (order.original.status === 1) {
        totalCompleted++;
      }
      if (order.original.status === 3) {
        inProgress++;
      }
      if (order?.original?.runningLate) {
        runningLate++;
      }
      if (order?.original?.completedLate) {
        completedLate++;
      }
    }
  }

  return { totalOrders, totalCompleted, notStarted, runningLate, completedLate, inProgress };
};

export const parseImage = (vendor: string) => {
  const trimmed = vendor.trim();
  const imageUrl = `https://onecart.s3-eu-west-1.amazonaws.com/website/logos/${trimmed}.png`;
  return imageUrl;
};

export function mapProductFulfillment(products: Product[], vendorName: string) {
  let foundItems: Product[] = [],
    outOfStockItems: Product[] = [],
    substitutedItems: Product[] = [],
    partialItems: Product[] = [],
    foundTotal = 0,
    substitutedTotal = 0,
    partialTotal = 0,
    outOfStockTotal = 0,
    vendorCredit = 0;
  products.forEach((product) => {
    if (product.vendor === vendorName) {
      switch (product.status) {
        case ShopperOrderProductStatus.COMPLETED:
          foundItems.push(product);
          foundTotal += product.orderQuantity * product.price;
          product.total = product.suppliedQuantity * product.price;
          break;
        case ShopperOrderProductStatus.REFUND:
        case ShopperOrderProductStatus.NOT_DONE:
          outOfStockTotal += product.orderQuantity * product.price;
          outOfStockItems.push(product);
          vendorCredit += product.orderQuantity * product.price;
          product.total = product.suppliedQuantity * product.price;
          break;
        case ShopperOrderProductStatus.CUSTOMER_SUB:
        case ShopperOrderProductStatus.SHOPPER_SUB:
          substitutedItems.push(product);
          substitutedTotal += product.substitutionQuantity * product.substitutionPrice;
          vendorCredit +=
            product.orderQuantity * product.price - product.substitutionQuantity * product.substitutionPrice;
          product.total = product.substitutionQuantity * product.substitutionPrice;
          break;
        case ShopperOrderProductStatus.PARTIAL:
          partialItems.push(product);
          partialTotal += product.suppliedQuantity * product.price;
          vendorCredit += product.orderQuantity * product.price - product.suppliedQuantity * product.price;
          product.total = product.suppliedQuantity * product.price;
          break;
        default:
          break;
      }
    }
  });

  return {
    foundItems,
    outOfStockItems,
    substitutedItems,
    partialItems,
    foundTotal,
    substitutedTotal,
    partialTotal,
    outOfStockTotal,
    vendorCredit,
  };
}

export function isRunningLate(order: Order) {
  if (!order || !order?.status) return null;

  if ([OrderStatus.LATE].includes(order.status)) return true;

  if (
    [OrderStatus.COMPLETED, OrderStatus.CANCELLED, OrderStatus.NO_ITEMS_FOUND, OrderStatus.ON_HOLD].includes(
      order.status
    )
  )
    return false;

  const deliveryDate = moment(`${order.deliveryDate}T${order.deliveryTo}:00+0200`);

  return moment().isSameOrAfter(deliveryDate);
}

export function isCompletedLate(order: Order) {
  if (!order || !order?.status) return null;

  if (order.status !== OrderStatus.COMPLETED) return false;

  const deliveryDate = `${order.deliveryCompleted}+0200`.replace(' ', 'T');

  const expectedDeliveryDate = moment(`${order.deliveryDate}T${order.deliveryTo}:00+0200`);

  return moment(deliveryDate).isAfter(expectedDeliveryDate);
}
export function hasPickupOrDropOffException(order: Order) {
  if (!order || !order?.status) return null;

  if (order.status !== OrderStatus.COMPLETED) return false;

  if (order.pickup_exception || order.drop_off_exception) return true;
}
