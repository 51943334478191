export enum OrderHighlightingRuleTarget {
  DELIVERY_FROM = 'delivery_from',
  DELIVERY_TO = 'delivery_to',
}

export enum OrderHighlightingRuleStatus {
  WAITING = 1,
  SHOPPER_STARTED = 2,
  SHOPPER_COMPLETED = 3,
  DRIVER_STARTED = 4,
  DRIVER_COMPLETED = 5,
  CANCELLED = 6,
  NO_ITEMS_FOUND = 7,
  ON_HOLD = 8,
}

export enum OrderStatus {
  INVALID = 0,
  COMPLETED = 1,
  NOT_STARTED = 2,
  ACTIVE = 3,
  LATE = 4,
  CANCELLED = 5,
  ON_THE_WAY = 6,
  PENDING_DRIVER = 7,
  NO_ITEMS_FOUND = 8,
  ON_HOLD = 9,
  PICKUP_EXCEPTION = 10,
  DROP_OFF_EXCEPTION = 11,
  PICKUP_STARTED = 12,
  PICKUP_COMPLETED = 13,
}

export enum SpotlightPermission {
  DELETE_ORDER = 'delete_order',
  EDIT_ORDER = 'edit_order',
  ASSIGN_AGENT = 'assign_agent',
  CREATE_PICUP = 'create_picup',
  SPOTLIGHT = 'spotlight',
  VENDOR_SWITCH = 'vendor_switch',
  VENDOR_TOGGLE = 'vendor_toggle',
  ORDER_WEBHOOK = 'order_webhook',
  HOLD_ORDER = 'hold_order',
  UPDATE_PRICE = 'update_price',
  SEE_ALL = 'see_all',
  FILTER_DATE = 'filter_date',
  FILTER_SHOPS = 'filter_shops',
  AUTO_ASSIGN = 'auto_assign',
  ALL_SHOPS = 'all_shops',
  VIEW_ANALYTICS = 'view_analytics',
  RESET_ORDER = 'reset_order',
  RESET_ORDER_HIGH_RISK = 'reset_order_high_risk',
  RESET_ORDER_ADMIN = 'reset_order_admin',
}

export enum ShopperStatusOption {
  AVAILABLE = 1,
  SHOPPING = 2,
  HANDOVER = 3,
  SHORT_BREAK = 4,
  LUNCH_BREAK = 5,
  COACHING = 6,
  ONE_TO_ONE = 7,
  TEAM_BRIEFING = 8,
}
