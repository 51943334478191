import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useWindowParams } from '../hooks/useWindowParams';
import { BreakPoints } from '../theme/breakPoints';
import { parseImage } from '../utils/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface ParamTypes {
  orderID: string;
}
interface IState {
  shopper: string;
  isCompleted: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
  },
  appBar: {
    backgroundColor: 'white',
    color: '#44444F',
    borderRadius: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  list: {
    width: '100%',
  },
  productList: {
    width: '100%'
  },
  productListItem: {
    width: '100%',
    display: 'grid',
    borderRadius: '4px',
    marginBottom: '5px',
    marginRight: theme.spacing(10),
    gridTemplateColumns: 'auto 1fr',
    '&.substitute': {
      paddingTop: '0px',
      paddingBottom: '0px',
      marginBottom: '0px',
    }
  },
  productListItemContentWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto',
    [theme.breakpoints.up(BreakPoints.sm)]: {
      gap: '3rem',
      gridTemplateColumns: '30% 1fr',
      gridTemplateRows: 'auto'
    },
  },
  productListItemContentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto'
  },
  itemText: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    '& span': {
      gridRow: 2,
      verticalAlign: 'middle'
    },
  },
  productImage: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  listAvatar: {
    maxWidth: '10%',
    marginRight: '20px',
  },
}));

const useDynamicStyles = makeStyles(() => ({
  productListItem: {
    background: (props?: any) => props?.background ?? 'none',
    '&:hover': {
      backgroundColor: (props?: any) => props?.background ?? 'none',
    }
  },
}));

enum orderProductStatus {
  NOT_DONE = 0,
  COMPLETED = 1,
  CUSTOMER_SUB = 2,
  REFUND = 3,
  SHOPPER_SUB = 4,
  PARTIAL = 5,
  WAITING = 6,
}

const productRowColors: any = {
  0: 'none',
  1: '#95c842',
  2: '#FBA401',
  3: 'red',
  4: '#FBA401',
  5: '#45D8E5',
  6: 'none',
};

const substitutionColors: any = {
  1: '#DD730C',
  0: '#5FC6E5',
};

const VendorShoppingList = () => {
  const history = useHistory();
  const classes = useStyles();
  let { orderID } = useParams<ParamTypes>();
  const location = useLocation();
  const vendor = (location.state as any)?.vendor;
  const isCompleted = (location.state as IState).isCompleted;

  const [value, setValue] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<any>('');
  const [totalAmount, setTotalAmount] = useState<number>(0);

  useEffect(() => {
    const allProducts: any = vendor?.products;
    if (allProducts && allProducts.length > 0) {
      let total: number = 0;

      allProducts.forEach((product: any) => {
        if (product.status === orderProductStatus.COMPLETED || product.status === orderProductStatus.PARTIAL) {
          const productPrice = Number(product.price) * Number(product.orderQuantity);
          total += productPrice;
        } else if (
          product.status === orderProductStatus.CUSTOMER_SUB ||
          product.status === orderProductStatus.SHOPPER_SUB
        ) {
          const productPrice = Number(product.substitutionPrice) * Number(product.substitutionQuantity);
          total += productPrice;
        }
      });

      setTotalAmount(total);
    }
  }, [vendor.products]);

  const handleClick = (productID: any) => {
    if (selectedIndex === productID) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(productID);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const backToOrder = () => {
    history.push(`/orders/${orderID}`, { isCompleted });
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          <List className={classes.list}>
            <ListItem>
              <IconButton onClick={() => backToOrder()} edge="start" color="inherit" aria-label="open drawer">
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6">
                Shopping List: {orderID}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={parseImage(vendor.vendor)}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={vendor.vendor} />
              <Typography variant="body1" align="right">
                Amount to Swipe: {totalAmount}
              </Typography>
            </ListItem>
          </List>
        </Toolbar>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Vendor shopping list "
          indicatorColor="primary"
          variant="fullWidth"
        >
          <Tab label="Shopping List" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <List className={classes.productList}>
          {vendor.products.map((product: any) => {
            const substitutedItems =
              product.status === orderProductStatus.CUSTOMER_SUB || product.status === orderProductStatus.SHOPPER_SUB;
            const partialItems = product.status === orderProductStatus.PARTIAL;

            return (
              <Fragment key={product.productID}>
                <ProductCard
                  selectedIndex={selectedIndex}
                  handleClick={() => handleClick(product.productID)}
                  showAccordionBtn={substitutedItems || partialItems}
                  background={productRowColors[product.status]}
                  product={product}
                />
                {((product.substitutionQuantity && substitutedItems) || (partialItems && product.suppliedQuantity)) && (
                  <Collapse
                    in={product.productID === selectedIndex}
                    timeout="auto"
                    unmountOnExit
                    style={{
                      top: '-5px',
                      position: 'relative',
                      height: '100px',
                    }}
                  >
                    <List className={classes.productList}>
                      <ProductCard
                        substitute
                        selectedIndex={selectedIndex}
                        background={substitutionColors[Number(substitutedItems)]}
                        product={product}
                      />
                    </List>
                  </Collapse>
                )}
              </Fragment>
            );
          })}
        </List>
      </TabPanel>
    </div>
  );
};

function ProductCard({ product, background, substitute, handleClick, selectedIndex, showAccordionBtn }: any) {
  const classes = useStyles();
  const dynamicStyle = useDynamicStyles({ background });
  const { width: pageWidth } = useWindowParams();

  const productNameLabel = useMemo(() => {
    const isDesktop = pageWidth >= BreakPoints.sm;
    return isDesktop ? 'Product' : '';
  }, [pageWidth]);

  const productDetails = useMemo(() => ({
    productID: product.productID,
    price: substitute ? product.substitutionPrice : product.price,
    imageURL: substitute ? product.substitutionImageURL : product.imageURL,
    orderQuantity: substitute ? (product.substitutionQuantity || product.suppliedQuantity) : product.orderQuantity,
    name: substitute ? product.substitutionName : product.name
  }), [product, substitute]);

  return (
    <ListItem
      button={true}
      className={`${classes.productListItem} ${dynamicStyle.productListItem} ${!!substitute ? 'substitute' : ''}`}
      onClick={handleClick}
    >
      <ListItemAvatar className={classes.listAvatar}>
        <Avatar className={classes.productImage} src={productDetails.imageURL} />
      </ListItemAvatar>

      <div className={classes.productListItemContentWrapper}>
        <ListItemText className={classes.itemText} primary={productDetails.name} secondary={productNameLabel} />
        <div className={classes.productListItemContentContainer}>
          <ListItemText className={classes.itemText} secondary="Quantity" primary={productDetails.orderQuantity} />
          <ListItemText className={classes.itemText} secondary="Price" primary={productDetails.price.toFixed(2)} />
        </div>
      </div>

      {showAccordionBtn && (
        <>{productDetails.productID === selectedIndex ? <ExpandLess /> : <ExpandMore />}</>
      )}
    </ListItem>
  );


}

export default VendorShoppingList;
